import ReactDOM from 'react-dom/client'
import React from 'react'

import './sass/index.scss'

import { App } from './components'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
