import { forwardRef, DetailedHTMLProps, useEffect, useRef } from "react";

import styles from './Spotlight.module.scss'

type SpotlightProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;;

export function Spotlight({ className = '', children, ...props }: SpotlightProps = {}) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        return window.addEventListener('mousemove', (e) => {
            const container = ref.current

            if (!container || !container.children) return

            for (const child of Array.from(container.children) as HTMLElement[]) {
                const rect = child.getBoundingClientRect()
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                child.style.setProperty('--mouse-x', `${x}px`)
                child.style.setProperty('--mouse-y', `${y}px`)
            }
        })
    })

    return <div {...props} ref={ref} className={`${styles.container} ${className}`}>{children}</div>
}

interface SpotlightCardProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    animated?: boolean
    rounded?: boolean
}

export const SpotlightCard = forwardRef<HTMLDivElement, SpotlightCardProps>(({ className = '', rounded, animated, children, ...props }: SpotlightCardProps = {}, ref) => {
    return (
        <div ref={ref} className={`${styles.card} ${rounded ? styles.rounded : ''} ${animated ? styles.animated : ''} ${className}`}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
})
