import { useInView } from 'react-intersection-observer'

import styles from './About.module.scss'

import { about } from '../../config'

export function About() {
	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 })

	return (
		<section ref={ref} className={`${styles.about} slide-in-bottom ${inView ? 'visible' : ''}`}>
			<h1 className='title'>About</h1>
			{typeof about === 'string' ? <p>{about}</p> : about}
		</section>
	)
}
