import { useInView } from 'react-intersection-observer'

import { Spotlight, SpotlightCard } from '../Spotlight'
import { links as l } from '../../config'

import styles from './Footer.module.scss'

export function Footer() {
	const { ref, inView } = useInView({ triggerOnce: true })

	return (
		<Spotlight>
			<SpotlightCard ref={ref} className={`${styles.footer} fade-in ${inView ? 'visible' : ''}`}>
				<div className='container'>
					<a className={styles.logo} href='/'>
						NK
					</a>
					<ul>
						{l.telegram && (
							<li>
								<a href={l.telegram} target='_blank' rel='noreferrer'>
									<div className='icon telegram'></div>
								</a>
							</li>
						)}
						{l.gitHub && (
							<li>
								<a href={l.gitHub} target='_blank' rel='noreferrer'>
									<div className='icon github'></div>
								</a>
							</li>
						)}
						{l.linkedIn && (
							<li>
								<a href={l.linkedIn} target='_blank' rel='noreferrer'>
									<div className='icon linkedin'></div>
								</a>
							</li>
						)}
					</ul>
				</div>
			</SpotlightCard>
		</Spotlight>
	)
}
