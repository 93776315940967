import { useInView } from 'react-intersection-observer'

import { Spotlight } from '../Spotlight'
import { projects } from '../../config'
import { Project } from './Project'

import styles from './Projects.module.scss'

export interface ILink {
	name: string
	link: string
}

export interface IProject {
	name: string
	date: string
	description: string
	links?: ILink[]
	technologies?: string[]
}

export function Projects() {
	const { ref, inView } = useInView({ triggerOnce: true })

	if (!projects || projects.length === 0) return null

	return (
		<section ref={ref}>
			<h1 className={`title slide-in-bottom ${inView ? 'visible' : ''}`}>Projects</h1>
			<Spotlight className={styles.projects}>
				{projects.map((project, index) => (
					<Project project={project} key={index} />
				))}
			</Spotlight>
		</section>
	)
}
