import { Fragment } from 'react'

import { Contacts } from './Contacts'
import { Projects } from './Projects'
import { Footer } from './Footer'
import { Skills } from './Skills'
import { About } from './About'
import { Hero } from './Hero'

export function App() {
	return (
		<Fragment>
			{/* <Header /> */}
			<Hero />
			<div className='container'>
				<About />
				<Skills />
				<Projects />
				<Contacts />
			</div>
			<Footer />
		</Fragment>
	)
}
