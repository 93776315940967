import { useInView } from 'react-intersection-observer'

import { SpotlightCard } from '../Spotlight'
import { IProject } from './Projects'

import styles from './Project.module.scss'

interface TechnologiesProps {
	technologies?: string[]
}

function Technologies({ technologies }: TechnologiesProps) {
	const { ref, inView } = useInView({ triggerOnce: true })

	if (!technologies || technologies.length === 0) return null

	return (
		<ul ref={ref} className={styles.technologies}>
			{technologies.map((t, i) => (
				<li key={i} style={{ animationDelay: `${i * 75}ms` }} className={`fade-in ${inView ? 'visible' : ''}`}>
					<div className='gradient-item'>{t}</div>
				</li>
			))}
		</ul>
	)
}

export interface ProjectProps {
	project: IProject
}

export function Project({ project: { name, date, description, links, technologies } }: ProjectProps) {
	const { ref, inView } = useInView({ triggerOnce: true })

	return (
		<div ref={ref} className={`slide-in-bottom ${inView ? 'visible' : ''}`}>
			<SpotlightCard rounded animated className={styles.project}>
				<div className={styles.content}>
					<h2>{name}</h2>
					<span>{date}</span>
					<p>{description}</p>
					{links && links.length > 0 && (
						<ul className={styles.links}>
							{links.map(({ link, name }, index) => (
								<li key={index}>
									<a className='icon anchor' href={link} target='_blank' rel='noreferrer'>
										{name}
									</a>
								</li>
							))}
						</ul>
					)}
					<Technologies technologies={technologies} />
				</div>
			</SpotlightCard>
		</div>
	)
}
