import { useInView } from 'react-intersection-observer'

import { Spotlight, SpotlightCard } from '../Spotlight'
import { links as l, info as i, cv } from '../../config'

import styles from './Contacts.module.scss'

export function Contacts() {
	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.25 })

	return (
		<section ref={ref} id='contacts' className={`slide-in-bottom ${inView ? 'visible' : ''}`}>
			<h1 className='title'>Contacts</h1>
			<Spotlight className={styles['slide-in-bottom']}>
				<SpotlightCard rounded animated>
					<div className={styles.card}>
						<ul className={styles.info}>
							{i.phone && (
								<li>
									<strong>Phone:</strong>
									<a href={`tel:${i.phone}`}>{i.phone}</a>
								</li>
							)}
							{i.email && (
								<li>
									<strong>E-mail:</strong>
									<a href={`mailto:${i.email}`}>{i.email}</a>
								</li>
							)}
							{i.location && (
								<li>
									<strong>Location:</strong>
									<div>{i.location}</div>
								</li>
							)}
						</ul>
						<ul className={styles.links}>
							{l.linkedIn && (
								<li>
									<a className='icon linkedin' href={l.linkedIn} target='_blank' rel='noreferrer'>
										LinkedIn
									</a>
								</li>
							)}
							{l.telegram && (
								<li>
									<a className='icon telegram' href={l.telegram} target='_blank' rel='noreferrer'>
										Telegram
									</a>
								</li>
							)}
							{l.gitHub && (
								<li>
									<a className='icon github' href={l.gitHub} target='_blank' rel='noreferrer'>
										GitHub
									</a>
								</li>
							)}
							{l.leetCode && (
								<li>
									<a className='icon leetcode' href={l.leetCode} target='_blank' rel='noreferrer'>
										Leet Code
									</a>
								</li>
							)}
						</ul>
						<a className='button' href={cv.path} target='_blank' rel='noreferrer'>
							Download CV
						</a>
					</div>
				</SpotlightCard>
			</Spotlight>
		</section>
	)
}
