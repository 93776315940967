import { ReactElement } from 'react'

import { IProject } from './components/Projects'
import { ISkill } from './components/Skills'

// const testSkills: ISkill[] = [
// 	['Front End', ['React', 'Redux', 'HTML', 'CSS', 'SCSS', 'Figma', 'Formik']],
// 	['Back End', ['NodeJS', 'PostgreSQL', 'TypeORM', 'Redis', 'Docker', 'NestJS', 'TypeGraphQL', 'NGINX', 'Socket IO', 'Express']],
// 	['Common', ['TypeScript', 'JavaScript', 'Git', 'SOLID', 'GoF Patterns', 'TCP/IP', 'DSA', 'GraphQL', 'Jest', 'Linux', 'RegExp', 'TDD']]
// ]

// const testProjects: IProject[] = [
// 	{
// 		name: 'Invoice App',
// 		date: 'July - Aug 2022',
// 		description:
// 			'Developed and styled interactive web apps for Apple Music, including the UI of Apple Music’s embeddable web player widget for in-browser user authorization and full song playback.',
// 		links: [
// 			{ name: 'Demo', link: '#' },
// 			{ name: 'GitHub', link: '#' }
// 		],
// 		technologies: ['NodeJS', 'PostgreSQL', 'TypeGraphQL', 'TypeORM', 'NGINX', 'Docker', 'React', 'SASS', 'Formik']
// 	},
// 	{
// 		name: 'File Converter',
// 		date: 'July - Aug 2022',
// 		description:
// 			'Developed and styled interactive web apps for Apple Music, including the UI of Apple Music’s embeddable web player widget for in-browser user authorization and full song playback.',
// 		links: [
// 			{ name: 'Demo', link: '#' },
// 			{ name: 'GitHub', link: '#' }
// 		],
// 		technologies: ['NodeJS', 'PostgreSQL', 'NestJS', 'TypeORM', 'NGINX', 'Docker', 'React', 'SASS', 'Formik']
// 	},
// 	{
// 		name: 'react-theme-lib',
// 		date: 'Mar 2022',
// 		description:
// 			'Developed and styled interactive web apps for Apple Music, including the UI of Apple Music’s embeddable web player widget for in-browser user authorization and full song playback.',
// 		links: [
// 			{ name: 'NPM', link: '#' },
// 			{ name: 'GitHub', link: '#' }
// 		]
// 	},
// 	{
// 		name: 'Project with no links',
// 		date: 'Mar 2022',
// 		description:
// 			'Developed and styled interactive web apps for Apple Music, including the UI of Apple Music’s embeddable web player widget for in-browser user authorization and full song playback.'
// 	}
// ]

export type About = ReactElement | string

export interface Links {
	leetCode?: string
	linkedIn?: string
	telegram?: string
	gitHub?: string
}

export interface Info {
	location: string
	phone: string
	email: string
}

export interface CV {
	path: string
}

export const skills: ISkill[] = [
	['Front End', ['React', 'HTML', 'CSS', 'SCSS', 'React Query', 'React Router', 'Figma', 'Formik']],
	['Back End', ['NodeJS', 'PostgreSQL', 'NestJS', 'TypeORM', 'Redis', 'Docker', 'TypeGraphQL', 'NGINX', 'Express', 'AWS (S3, EC2)']],
	['Common', ['TypeScript', 'JavaScript', 'Git', 'SOLID', 'TCP/IP', 'DSA', 'GraphQL', 'Swagger', 'Jest', 'Linux', 'RegExp']]
]

export const projects: IProject[] = [
	{
		name: 'Link Shortener',
		date: 'Dec 2023',
		description:
			'Link shortener application with comprehensive click analytics that gathers statistics about the browser, operating system, and device from which the click was made.',
		links: [
			{ name: 'Demo', link: 'https://shortener.khodakovsky.com' },
			{ name: 'GitHub', link: 'https://github.com/NikitaKhodakovsky/link-shortener' }
		],
		technologies: [
			'NodeJS',
			'NestJS',
			'TypeORM',
			'PostgreSQL',
			'Redis',
			'Express',
			'Docker',
			'NGINX',
			'React',
			'SASS',
			'Formik',
			'Swagger'
		]
	},
	{
		name: 'Invoice App',
		date: 'Jun 2022',
		description: 'Application for creating and managing invoices.',
		links: [
			{ name: 'Demo', link: 'https://invoices.khodakovsky.com' },
			{ name: 'GitHub', link: 'https://github.com/NikitaKhodakovsky/invoice-app' }
		],
		technologies: [
			'NodeJS',
			'TypeGraphQL',
			'TypeORM',
			'PostgreSQL',
			'Redis',
			'Express',
			'Docker',
			'NGINX',
			'React',
			'SASS',
			'Formik',
			'Jest'
		]
	},
	{
		name: 'To Do App',
		date: 'May 2022',
		description: 'Simple task management app with light and dark themes.',
		links: [{ name: 'GitHub', link: 'https://github.com/NikitaKhodakovsky/todo-app' }],
		technologies: ['NodeJS', 'TypeGraphQL', 'TypeORM', 'SQLite', 'Apollo Server', 'Express', 'Docker', 'NGINX', 'React', 'SASS']
	},

	{
		name: 'react-theme-lib',
		date: 'May 2022',
		description: 'Simple library for working with themes in React with support of prefers-color-scheme media query.',
		links: [
			{ name: 'NPM', link: 'https://www.npmjs.com/package/react-theme-lib' },
			{ name: 'GitHub', link: 'https://github.com/NikitaKhodakovsky/react-theme-lib' }
		]
	},
	{
		name: 'REST Countries',
		date: 'Nov 2021',
		description: 'Simple, easy-to-use application to explore information about the countries and regions of the world.',
		links: [
			{ name: 'Demo', link: 'https://countries.khodakovsky.com' },
			{ name: 'GitHub', link: 'https://github.com/NikitaKhodakovsky/rest-countries' }
		],
		technologies: ['React', 'React Query', 'NGINX', 'Docker']
	}
]

export const about: About =
	'Highly motivated Full-Stack developer with 2 years of hands-on experience in web development. Proficient in TypeScript, JavaScript, SQL, and NodeJS. I aspire to be part of a friendly team of professionals doing big, significant projects in which I can unleash my full potential to achieve personal and company goals.'

export const links: Links = {
	linkedIn: '#',
	telegram: 'https://t.me/nikitakhodakovsky',
	gitHub: 'https://github.com/NikitaKhodakovsky'
}

export const info: Info = {
	location: 'Odessa, Ukraine',
	phone: '+ 38 (066) 520 81 41',
	email: 'contact@khodakovsky.com'
}

export const cv: CV = {
	path: `${process.env.PUBLIC_URL}/Nikita Khodakovsky - (Full-Stack Developer).pdf`
}
