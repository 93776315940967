import { Bokeh } from '../Bokeh'

import { cv } from '../../config'

import styles from './Hero.module.scss'

export function Hero() {
	return (
		<section className={styles.hero}>
			<Bokeh />
			<div className='container'>
				<h1 className='tracking-in-expand'>Nikita Khodakovsky</h1>
				<h2 className='tracking-in-expand'>NodeJS Developer</h2>
				<div className={`text-focus-in ${styles.actions}`}>
					<a className='button' href={cv.path} target='_blank' rel='noreferrer'>
						Download CV
					</a>
					<a className='button' href='#contacts'>
						Contacts
					</a>
				</div>
			</div>
		</section>
	)
}
