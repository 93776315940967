import { useInView } from 'react-intersection-observer'

import { skills } from '../../config'

import styles from './Skills.module.scss'

export type ISkill = [string, string[]]

function render(skills: ISkill[], inView: boolean) {
	const delay = 50
	let current = 1

	const render = (skill: string, index: number) => (
		<li key={index} className={`fade-in ${inView ? 'visible' : ''}`} style={{ animationDelay: `${current++ * delay}ms` }}>
			<div key={index} className='gradient-item'>
				{skill}
			</div>
		</li>
	)

	return skills.map(([t, s], index) => (
		<div key={index}>
			<h2>{t}</h2>
			<ul>{s.map(render)}</ul>
		</div>
	))
}

export function Skills() {
	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.15 })

	if (!skills || skills.length === 0) return null

	return (
		<section ref={ref} className={`${styles.skills} ${inView ? styles.visible : ''}`}>
			<h1 className='title'>Skills</h1>
			{render(skills, inView)}
		</section>
	)
}
